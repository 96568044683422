/**
 * Created by simon on 2018-11-30.
 */

import _             from 'lodash';
import Formatter     from './Formatter.js';
import isValidPersNr from './helpers/isValidPersNr.js';
import isValidOrgnr  from './helpers/isValidOrgnr.js';
import isValidEmail  from './helpers/isValidEmail.js';
import isValidDate   from './helpers/isValidDate.js';

class Validate {
	static multiple(options, fns = []) {
		for(const fn of fns) {
			const res = fn(options);

			if(res !== true) {
				return res;
			}
		}

		return true;
	}

	static validLogin(options) {
		return Validate.multiple(options, [
			Validate.required,
			Validate.maxLength(50),
		]);
	}

	static validPassword({value}) {
		if(!_.isString(value)) {
			return 'Lösenord är obligatoriskt.';
		}

		if(value.length < 6) {
			return 'Lösenord måste bestå av minst 6 tecken.';
		}

		return true;
	}

	static required({value, label}, {allowZero = true} = {}) {
		if((_.isEmpty(value) && !_.isNumber(value)) || _.isUndefined(value) || value === '00000000-0000-0000-0000-000000000000' || (value === 0 && !allowZero) || value === '1899-12-30') {
			return label && label.trim() ? `${label} är obligatoriskt.` : 'Obligatoriskt fält.';
		}

		return true;
	}

	static requiredIf(predicate) {
		return ({value, label, context}) => {
			if(predicate(context)) {
				return Validate.required({value, label});
			}

			return true;
		};
	}

	static oneOf(values) {
		return ({value}) => {
			if(value && !_.includes(values, value)) {
				return 'Felaktigt värde.';
			}

			return true;
		};
	}

	static requiredIfOtherFieldHasValue(fieldName) {
		return ({value, label, context}) => {
			if(!_.isEmpty(context[fieldName])) {
				return Validate.required({value, label});
			}

			return true;
		};
	}

	static maxLength(length = Infinity) {
		return ({value, label}) => {
			if(_.isString(value) && value.length > length) {
				return `${label} får bestå av max ${length} tecken.`;
			}

			return true;
		};
	}

	static minLength(length = 1) {
		return ({value, label}) => {
			if(_.isString(value) && value.length < length) {
				return `${label} måste bestå av minst ${length} tecken.`;
			}

			return true;
		};
	}

	static validInteger({value, label}) {
		if(value === '00' || _.isNaN(parseInt(value, 10)) || !_.isInteger(_.toNumber(value))) {
			return `${label} måste vara ett giltigt heltal.`;
		}

		return true;
	}

	static numberBetween(min, max) {
		return ({value, label}) => {
			const val = _.toNumber(Formatter.unformatDecimal(value));

			if(val < min || val > max) {
				return `${label} måste vara mellan ${min} och ${max}.`
			}

			return true;
		}
	}

	static numberGreaterThan(min) {
		return ({value, label}) => {
			const val = _.toNumber(Formatter.unformatDecimal(value));

			if(val <= min) {
				return `${label} måste vara större än ${min}.`
			}

			return true;
		}
	}

	static disallowNegativeNumber() {
		return ({value, label}) => {
			const val = _.toNumber(Formatter.unformatDecimal(value));

			if(val < 0) {
				return `${label} får inte vara ett negativt värde.`
			}

			return true;
		};
	}

	static validDate({value}) {
		if(!value) { //Use Validate.required
			return true;
		}

		if(!isValidDate(value, {deferValidationUntilFormatted: true})) {
			return 'Felaktigt datum';
		}

		return true;
	}

	static validTime({value}) {
		if(!value || value.length === 0) { //Use required
			return true;
		}

		value = value.replace(/\D+/g, '');

		let HH = value.slice(0, 2);
		let MM = value.slice(2);

		if(!MM) {
			MM = 0;
		}

		HH = parseFloat(HH);
		MM = parseFloat(MM);

		if(HH < 0 || HH > 24 || isNaN(HH) || MM < 0 || MM > 59 || isNaN(MM)) {
			return `Ogiltig tidpunkt.`;
		}

		return true;
	}

	static validPersNr({value, allowSamordningsnummer = true}) {
		if(value && !isValidPersNr(value, {allowSamordningsnummer})) {
			return `Felaktigt personnummer/sammordningsnummer.`
		}
		
		return true;
	}

	static validOrgNr({value}) {
		if(!isValidOrgnr(value)) {
			return 'Felaktigt organisationsnummer.';
		}

		return true;
	}

	static validEmail({value}) {
		if(value && !isValidEmail(value)) {
			return 'Felaktig epost.';
		}

		return true;
	}

	static alphanumeric({label, value, allowSpace = false}) {
		const regExp = allowSpace
			? /[\d a-zäåö]+/gi
			: /[\da-zäåö]+/gi;

		if(value && value.replace(regExp, '') !== '') {
			return `${label} får endast bestå av bokstäver och siffror.`;
		}

		return true;
	}

	static isStringOfNumbers({label, value}) {
		if(value && value.replace(/\d+/gi, '') !== '') {
			return `${label} får endast bestå av siffror.`;
		}

		return true;
	}

	static validBICLength() {
		return ({value, label}) => {
			if(_.isString(value) && value && value.length !== 8 && value.length !== 11) {
				return `${label} måste vara antingen 8 eller 11 tecken långt.`;
			}

			return true;
		};
	}
}

export default Validate;
