<template>
	<v-slide-x-transition hide-on-leave>
		<v-autocomplete
						@keydown.space="onToggleSelection($event)"
						v-model="dataModel[name]"
						:rules="rules || model.$rules[name]"
						v-bind="$attrs"
						v-on="$listeners"
						:label="noLabel ? '' : label ? label : model.constructor.propertyMap[name].label"
						:data-name="name"
						ref="field"
						validate-on-blur
						:data-tabordername="tabOrderName || name"
						autocomplete="off"
						color="accent"
						@focus="onFocus"
						@blur="onBlur"
						@click:clear="onClickClear"
						:menu-props="{auto: true}"
						persistent-hint
						persistent-placeholder
						@input="onModelValueChangedByComponent"
						item-color="accent"
		>
			<template slot="append-outer">
				<slot name="append-outer"></slot>
			</template>
			<template slot="prepend">
				<slot name="prepend"></slot>
			</template>
			<template v-if="hasSlot('selection')" v-slot:selection="item">
				<slot name="selection" v-bind="item"></slot>
			</template>
		</v-autocomplete>
	</v-slide-x-transition>
</template>

<script>
import {has, includes, find, get, isEqual, findIndex} from 'lodash';

export default {
	name: 'SelectField',

	props: {
		model: {
			required: true,
		},

		name: {
			required: true,
		},

		tabOrderName: {
			type:    String,
			default: '',
		},

		noLabel: {
			type:    Boolean,
			default: false,
		},

		label: {
			type:    String,
			default: '',
		},

		rules: {
			type:    Array,
			default: null,
		},

		preventTabOrderUnlessValid: {
			type:    Boolean,
			default: false,
		},

		preventTabOrderIfValueSearchedButNothingSelected: {
			type:    Boolean,
			default: false,
		},

		autoRefocusOnTabOrderNavigationFailed: {
			type:    Boolean,
			default: true,
		},

		openOnFocus: {
			type:    Boolean,
			default: true,
		},

		forcePlaceholder: {
			type:    Boolean,
			default: false,
		},
	},

	data() {
		return {
			selectedValuesStack: [],
			lastOnKeyDownEvent:  null,
		};
	},

	mounted() {
		const $ref = this.$refs.field || this.$refs[`${this.tabOrderNamePrefix}${this.name}`];
		const originalOnKeyDown = $ref.onKeyDown;

		$ref.onKeyDown = (event) =>  {
			this.lastOnKeyDownEvent = event;

			return event.keyCode === 13 || event.keyCode === 9 ? this.onAutoSelectOnEnter(event) : originalOnKeyDown(event);
		};
	},

	methods: {
		hasSlot(name) {
			return this.$slots[name] || this.$scopedSlots[name];
		},

		onModelValueChangedByComponent() {
			if(this.model[this.name] === null) {
				this.model[this.name] = undefined;
			}
		},

		onClickClear() {
			this.$nextTick(() => {
				this.selectedValuesStack = [];
				this.$emit('cleared');
			});
		},

		getLastOnKeyDownEvent() {
			return this.lastOnKeyDownEvent;
		},

		onAutoSelectOnEnter(event) {
			if(has(this.$attrs, 'multiple')) {
				return this.runTabOrderEvent(event);
			}

			const highlightedTile = this.getHighlightedTile();

			if(highlightedTile) {
				event.preventDefault();
				event.stopPropagation();
				highlightedTile.click();
				setTimeout(() => {
					this.runTabOrderEvent(event);
				}, 100);
			} else {
				const value = String(event.target.value);
				const item = this.$attrs.items.find((item) =>
					item[this.name] === value ||
						item[this.$refs.field.itemValue] === value ||
						item[this.$refs.field.itemText] === value
				);

				const prevValue = this.model[this.name];

				if(item) {
					if(this.$refs.field.returnObject) {
						this.model[this.name] = item;
					} else if(has(item, this.name)) {
						this.model[this.name] = item[this.name];
					} else {
						this.model[this.name] = item[this.$refs.field.itemValue];
					}
				}

				if(!isEqual(this.model[this.name], prevValue)) {
					this.$emit('change');
				}

				setTimeout(() => {
					this.closeMenu();
					this.runTabOrderEvent(event);
				}, 1);
			}
		},

		getHighlightedTile() {
			const $menu = get(this.$refs, 'field.$refs.menu', null);

			if($menu) {
				$menu.getTiles();
			}

			const tiles = get($menu, 'tiles', null);

			return find(tiles, (item) => includes(item.className, 'highlighted')) || find(tiles, (item) => includes(item.className, '--active'));
		},

		onFocus() {
			try {
				//This is done so that arrowkeys up/down works as inteded (pre-selects listIndex according to currently selected item)
				this.$refs.field.$refs.menu.listIndex = findIndex(this.$attrs.items, (item) => isEqual(this.model[this.name], this.$refs.field.returnObject ? item : item[this.$refs.field.itemValue]));
			} catch{}

			this.$eventBus.$emit('Help:SetFromFieldFocus', {
				model: this.model,
				name:  this.name,
			});
		},

		onBlur() {
			if(has(this.$attrs, 'multiple')) {
				const field = get(this, '$refs.field', null);

				//Clear search
				if(field) {
					field.lazySearch = '';
				}
			}
		},

		onToggleSelection(event) {
			if(has(this.$attrs, 'multiple')) {
				try {
					const tiles = get(this.$refs, 'field.$refs.menu.tiles', null);
					const highlightedTile = find(tiles, (item) => includes(item.className, 'highlighted'));

					if(highlightedTile) {
						event.preventDefault();
						event.stopPropagation();
						highlightedTile.click();
					}
				} catch{}
			}
		},

		focus() {
			this.$refs.field.focus();
			if(this.openOnFocus) {
				this.$refs.field.activateMenu();
			}
		},

		closeMenu() {
			if(this.$refs.field.isMenuActive) {
				this.$refs.field.blur();
			}
		},

		isMenuOpen() {
			return this.$refs.field.isMenuActive;
		},

		blur() {
			this.resetValidation();

			this.$refs.field.blur();
		},

		resetValidation() {
			this.$refs.field.resetValidation();
		},

		checkForTabOrderEvent(event) {
			if(event.keyCode === 9 || event.keyCode === 13) {
				this.runTabOrderEvent(event);
			}
		},

		runTabOrderEvent(event) {
			const valueBeforeEvent = event.target.value;

			if(this.preventTabOrderUnlessValid && !this.$refs.field.validate()) {
				return false;
			}

			if(this.preventTabOrderIfValueSearchedButNothingSelected && get(this, '$refs.field.lazySearch', null) && get(this, '$refs.field.filteredItems', []).length === 0) {
				this.$emit('enterSelectedSearchItemDoesNotExist');
				this.$refs.field.blur();

				this.$nextTick(() => {
					setTimeout(() => {
						this.focus(true);
					}, 100);
				});
				
				return false;
			}

			this.$refs.field.blur();
			const didNavigate = this.$checkForTabOrderEvent(event);

			//Fix for when pressing enter === sets first value from selectlist despite user not searching/selecting a value
			if(event.keyCode === 13 && !valueBeforeEvent && !has(this.$attrs, 'multiple')) {
				this.model[this.name] = '';
				this.$refs.field.reset();
			}

			if(!didNavigate && this.autoRefocusOnTabOrderNavigationFailed) {
				setTimeout(() => {
					this.focus(true);
				}, 1);
			}
		},
	},

	computed: {
		dataModel() {
			return this.forcePlaceholder ? {[this.name]: null} : this.model;
		},
	},
}
</script>

<style scoped>
	::v-deep(.v-input__icon--append) {
		cursor: pointer;
	}
</style>
